import styled from 'styled-components'

export const SectionWrap = styled.div`
  padding-top: 80px;
  padding-bottom: 80px;

  .item {
    display: flex;
    align-items: center;
  }
`
