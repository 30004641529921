import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import { SectionWrap } from './section.style'
import SectionTitle from '../../../../components/ui/section-title'
import SwiperSlider from '../../../../components/ui/swiper'
import ClientLogo from '../../../../components/ui/client-logo'
import styled from 'styled-components'
import { device } from '../../../../theme'

const Section = ({ slider, sliderStyle }) => {
  const logoData = useStaticQuery(graphql`
    query LogoQuery {
      townships: allContentfulGemeente(
        filter: { logo: { file: { contentType: { regex: "/^image/" } } } }
      ) {
        edges {
          node {
            id
            name
            website
            logo {
              file {
                url
                fileName
                contentType
              }
              fluid(maxWidth: 210, quality: 80) {
                ...GatsbyContentfulFluid_withWebp
              }
              description
            }
          }
        }
      }
    }
  `)

  const townships = logoData.townships.edges.map((edge) => edge.node)

  const StyledClientLogo = styled(ClientLogo)`
    width: 100%;
    max-width: 250px;

    @media ${device.xsmallOnly} {
      max-width: 170px;
    }
  `

  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={12}>
            <SectionTitle
              title="Deelnemende gemeenten"
              align="left"
              mb="30px"
            />
            <SwiperSlider {...slider} {...sliderStyle}>
              {townships.map((township) => {
                if (township.logo && township.logo.file) {
                  const logoAltText =
                    township.logo.description !== ''
                      ? township.logo.description
                      : `Logo ${township.name}`
                  return (
                    <div key={township.id} className="item">
                      <StyledClientLogo
                        path={township.website}
                        title={township.name}
                        imageAltText={logoAltText}
                        brandImage={
                          township.logo.fluid
                            ? township.logo
                            : township.logo.file.url
                        }
                        hoverImage={
                          township.logo.fluid
                            ? township.logo
                            : township.logo.file.url
                        }
                      />
                    </div>
                  )
                } else {
                  return ''
                }
              })}
            </SwiperSlider>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  )
}

Section.propTypes = {
  slider: PropTypes.object,
}

Section.defaultProps = {
  slider: {
    slidesPerView: 6,
    loop: true,
    arrows: true,
    spaceBetween: 30,
    autoplay: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      dynamicBullets: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 2,
      },
      575: {
        slidesPerView: 3,
      },
      767: {
        slidesPerView: 4,
      },
      991: {
        slidesPerView: 5,
      },
      1499: {
        slidesPerView: 6,
      },
    },
  },
  sliderStyle: {
    align: 'center',
  },
}

export default Section
