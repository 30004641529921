import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Image from '../../image'
import { BrandLogoWrap } from './client-logo.style'

const ClientLogo = ({
  path,
  brandImage,
  hoverImage,
  title,
  imageAltText,
  ...props
}) => {
  let brandImg
  let hoverImg

  if (brandImage.fixed && typeof brandImage.fixed !== 'function') {
    brandImg = <Img fixed={brandImage.fixed} alt={imageAltText} />
  } else if (brandImage.fluid) {
    brandImg = <Image fluid={brandImage.fluid} alt={imageAltText} />
  } else {
    brandImg = <img src={brandImage} className="img-fluid" alt={imageAltText} />
  }

  if (hoverImage) {
    if (hoverImage.fixed && typeof hoverImage.fixed !== 'function') {
      hoverImg = <Img fixed={hoverImage.fixed} alt={imageAltText} />
    } else if (hoverImage.fluid) {
      hoverImg = <Image fluid={hoverImage.fluid} alt={imageAltText} />
    } else {
      hoverImg = (
        <img src={hoverImage} className="img-fluid" alt={imageAltText} />
      )
    }
  }

  return (
    <BrandLogoWrap {...props}>
      <a
        href={path}
        target="_blank"
        rel="noopener noreferrer"
        title={`Open de ${title} website in een nieuw tabblad`}
        aria-label={`Open de ${title} website in een nieuw tabblad`}
      >
        <div className="brand-logo__image">{brandImg}</div>
        {(props.layout === 1 || props.layout === 2) && (
          <div className="brand-logo__image-hover">{hoverImg}</div>
        )}
      </a>
    </BrandLogoWrap>
  )
}

ClientLogo.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  imageAltText: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hoverImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

ClientLogo.defaultProps = {
  layout: 1,
  path: '/',
  title: 'Brand Logo',
}

export default ClientLogo
